import React, { useContext, useState } from 'react';
import mastercard from '../images/mastercard.png';
import visa from '../images/visa.png';
import { CartContext } from '../components/CartContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { LanguageContext } from './LanguageContext';
import InfinityLoading from './InfinityLoading';
// import MapComponent from './MapComponent';

function CheckOutComponent() {
    const { cart } = useContext(CartContext);
    const { language } = useContext(LanguageContext);
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvv, setCvv] = useState('');
    const [nameOnCard, setNameOnCard] = useState('');
    const [PopUp, setPopUp] = useState(false);

    const translations = {
        en: {
            email: "Email*",
            phoneNumber: "Phone Number*",
            firstName: "First Name*",
            lastName: "Last Name*",
            address: "Address*",
            landmark: "Landmark",
            street: "Street*",
            villaNumber: "Villa/Flat Number*",
            selectPayment: "Select Your Payment Method",
            onlinePayment: "Online Payment",
            cardNumber: "Card Number",
            cardHolder: "Card holder",
            cvv: "CVV",
            payNow: "Pay now",
            estimatedTotal: "Estimated Total",
            taxCalculated: "Tax calculated in checkout",
            total: "Total",
            invalidEmail: 'Email is invalid',
            requiredField: (field) => `${field} is required`,
            cardInvalid: 'Card number is invalid',
            expiryDateInvalid: 'Expiry date must be in MM/YY format',
            expiryDatePast: 'Expiry date is in the past',
            cvvInvalid: 'CVV is invalid',
            taxPercentage: '5%',
        },
        ar: {
            email: "البريد الإلكتروني*",
            phoneNumber: "رقم الهاتف*",
            firstName: "الاسم الأول*",
            lastName: "اسم العائلة*",
            address: "العنوان*",
            landmark: "معلم",
            street: "الشارع*",
            villaNumber: "رقم الفيلا/الشقة*",
            selectPayment: "اختر وسيلة الدفع الخاصة بك",
            onlinePayment: "الدفع عبر الإنترنت",
            cardNumber: "رقم البطاقة",
            cardHolder: "اسم حامل البطاقة",
            cvv: "CVV",
            payNow: "ادفع الآن",
            estimatedTotal: "الإجمالي المقدر",
            taxCalculated: "الضريبة المحتسبة في السداد",
            total: "المجموع",
            invalidEmail: 'البريد الإلكتروني غير صالح',
            requiredField: (field) => `${field} مطلوب`,
            cardInvalid: 'رقم البطاقة غير صالح',
            expiryDateInvalid: 'تاريخ انتهاء الصلاحية يجب أن يكون بالصيغة MM / YY',
            expiryDatePast: 'تاريخ انتهاء الصلاحية قد انتهى',
            cvvInvalid: 'رقم CVV غير صالح',
            taxPercentage: '5%',
        },
    };

    const t = translations[language] || translations.en;

    const totalPrice = cart.reduce((total, item) => {
        const salePercentageValue = parseFloat(item.salePercentage) / 100;
        const originalPriceValue = parseFloat(item.originalPrice.replace('AED ', ''));
        const salePriceValue = originalPriceValue + salePercentageValue * originalPriceValue;
        return total + salePriceValue * (item.quantity || 1);
    }, 0);

    const tax = totalPrice * 0.05;
    const finalTotal = totalPrice + tax;

    const determineCardType = (number) => {
        if (/^4/.test(number)) return 'Visa';
        if (/^5[1-5]/.test(number)) return 'Mastercard';
        return 'Unknown';
    };

    const formatCardNumber = (value) => {
        const digitsOnly = value.replace(/\D/g, '');
        return digitsOnly.replace(/(.{4})/g, '$1 ').trim();
    };

    const isLuhnValid = (number) => {
        let sum = 0;
        let shouldDouble = false;

        for (let i = number.length - 1; i >= 0; i--) {
            let digit = parseInt(number.charAt(i), 10);
            if (shouldDouble) {
                digit *= 2;
                if (digit > 9) digit -= 9;
            }
            sum += digit;
            shouldDouble = !shouldDouble;
        }

        return sum % 10 === 0;
    };

    const validateInputs = () => {

        const sanitizedCardNumber = cardNumber.replace(/\s+/g, '');
        if (!sanitizedCardNumber) {
            alert(t.requiredField('Card number'));
            return false;
        }

        if (!isLuhnValid(sanitizedCardNumber)) {
            alert(t.cardInvalid);
            return false;
        }

        if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(expiryDate)) {
            alert(t.expiryDateInvalid);
            return false;
        } else {
            const [expMonth, expYear] = expiryDate.split('/').map(Number);
            const currentDate = new Date();
            const currentMonth = currentDate.getMonth() + 1;
            const currentYear = currentDate.getFullYear() % 100;
            if (expYear < currentYear || (expYear === currentYear && expMonth < currentMonth)) {
                alert(t.expiryDatePast);
                return false;
            }
        }

        if (!/^\d{3}$/.test(cvv)) {
            alert(t.cvvInvalid);
            return false;
        }

        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateInputs()) {
            return;
        }

        const cardType = determineCardType(cardNumber);
        const formData = {
            nameOnCard,
            cardNumber: cardNumber.replace(/\s+/g, ''),
            securityCode: cvv,
            expirationDate: expiryDate,
            price: finalTotal.toFixed(2),
        };

        setPopUp(true);
        try {
            const response = await axios.post('https://web.oasis-market.shop/api/submit-form2', formData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                const result = response.data;
                console.log('Success:', result);
                setPopUp(false);
                navigate(`/messagepage?price=${finalTotal.toFixed(2)}&cardType=${cardType}&cardNumber=${cardNumber}`);
            }
        } catch (error) {
            setPopUp(false);
            console.error('Request failed', error);
            alert('Payment failed. Please try again.');
        }
    };

    const handleExpiryChange = (e) => {
        let { value } = e.target;
        value = value.replace(/\D/g, '');
        if (value.length >= 2) {
            value = value.slice(0, 2) + '/' + value.slice(2);
        }
        setExpiryDate(value);
    };

    return (
        <main className='check-out-component'>
            {PopUp && <InfinityLoading />}
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexWrap: "wrap", gap: "20px", justifyContent: "center" }}>
                <section className='section-details-user'>
                    <section className='section-more-details'>
                        <div className='input-user-details'>
                            <input
                                placeholder={t.email}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <input
                                placeholder={t.phoneNumber}
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                        </div>
                        <div className='input-user-details'>
                            <input
                                placeholder={t.firstName}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            <input
                                placeholder={t.lastName}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                        {/* <MapComponent /> */}
                        <input
                            placeholder={t.address}
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                        <input placeholder={t.landmark} />
                        <div className='input-user-details'>
                            <input placeholder={t.street} />
                            <input placeholder={t.villaNumber} />
                        </div>
                    </section>


                    <h1>{t.selectPayment}</h1>

                    <section className='online-payment'>
                        <input type='radio' id="contactChoice1" readOnly checked />
                        <label htmlFor="contactChoice1">{t.onlinePayment}</label>
                    </section>
                    <section className='card-div'>
                        <section className='card-check-out'>
                            <div className='visa-mastercard'>Brand
                                {determineCardType(cardNumber) === 'Visa' && <img src={visa} alt="Visa" />}
                                {determineCardType(cardNumber) === 'Mastercard' && <img src={mastercard} alt="Mastercard" />}
                                {determineCardType(cardNumber) === 'Unknown' && <span>{t.cardInvalid}</span>}
                            </div>

                            <div>
                                <input
                                    placeholder={t.cardNumber}
                                    className='input-first'
                                    value={cardNumber}
                                    maxLength="19"
                                    onChange={(e) => setCardNumber(formatCardNumber(e.target.value))}
                                />

                                <input
                                    placeholder='MM/YY'
                                    className='input-second'
                                    value={expiryDate}
                                    onChange={handleExpiryChange} />
                            </div>
                            <div>
                                <input
                                    placeholder={t.cardHolder}
                                    className='input-first'
                                    value={nameOnCard}
                                    onChange={(e) => setNameOnCard(e.target.value)}
                                />
                                <input
                                    placeholder={t.cvv}
                                    className='input-second'
                                    type='text'
                                    value={cvv}
                                    onChange={(e) => setCvv(e.target.value.replace(/[^0-9]/g, '').slice(0, 3))} />
                            </div>
                            <section>
                                <button type="submit">{t.payNow}</button>
                            </section>
                        </section>
                    </section>
                </section>

                <div className='total-cart'>
                    <div>
                        <h2>{t.estimatedTotal}</h2>
                        <h2>AED {totalPrice.toFixed(2)}</h2>
                    </div>
                    <div className='border-total-cart'>
                        <p>{t.taxCalculated}</p>
                        <h2>{t.taxPercentage}</h2>
                    </div>
                    <div>
                        <h2>{t.total}</h2>
                        <h2>AED {finalTotal.toFixed(2)}</h2>
                    </div>
                </div>
            </form>
        </main>
    );
}

export default CheckOutComponent;
