// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../fonts/Inter_24pt-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../fonts/Inter_24pt-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../fonts/Inter_24pt-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../fonts/Inter_28pt-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../../fonts/Gilroy-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../../fonts/Gilroy-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../../fonts/Gilroy-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: "IR";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
    font-family: "IL";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

@font-face {
    font-family: "ISB";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}


@font-face {
    font-family: "IB";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}


@font-face {
    font-family: "GR";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}
@font-face {
    font-family: "GB";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
}
@font-face {
    font-family: "GSB";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
}`, "",{"version":3,"sources":["webpack://./src/pages/Main/fonts.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,4CAA8C;AAClD;;AAEA;IACI,iBAAiB;IACjB,4CAA4C;AAChD;;AAEA;IACI,kBAAkB;IAClB,4CAA+C;AACnD;;;AAGA;IACI,iBAAiB;IACjB,4CAA2C;AAC/C;;;AAGA;IACI,iBAAiB;IACjB,4CAA0C;AAC9C;AACA;IACI,iBAAiB;IACjB,4CAAuC;AAC3C;AACA;IACI,kBAAkB;IAClB,4CAA2C;AAC/C","sourcesContent":["@font-face {\r\n    font-family: \"IR\";\r\n    src: url('../../fonts/Inter_24pt-Regular.ttf');\r\n}\r\n\r\n@font-face {\r\n    font-family: \"IL\";\r\n    src: url('../../fonts/Inter_24pt-Light.ttf');\r\n}\r\n\r\n@font-face {\r\n    font-family: \"ISB\";\r\n    src: url('../../fonts/Inter_24pt-SemiBold.ttf');\r\n}\r\n\r\n\r\n@font-face {\r\n    font-family: \"IB\";\r\n    src: url('../../fonts/Inter_28pt-Bold.ttf');\r\n}\r\n\r\n\r\n@font-face {\r\n    font-family: \"GR\";\r\n    src: url('../../fonts/Gilroy-Regular.ttf');\r\n}\r\n@font-face {\r\n    font-family: \"GB\";\r\n    src: url('../../fonts/Gilroy-Bold.ttf');\r\n}\r\n@font-face {\r\n    font-family: \"GSB\";\r\n    src: url('../../fonts/Gilroy-SemiBold.ttf');\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
