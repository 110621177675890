import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import whatsapp from '../images/header/whatsapp.png'
import logocenter from '../images/header/logocenter.png'
import bucket from '../images/header/bucket.png'
import { CartContext } from './CartContext';
import { LanguageContext } from './LanguageContext'; 

function HeaderComponent() {
    const { cart } = useContext(CartContext);
    const { language, changeLanguage } = useContext(LanguageContext); 
    const totalItems = cart.reduce((acc, item) => acc + item.quantity, 0);

    const handleLanguageChange = (event) => {
        changeLanguage(event.target.value);
    };

    return (
        <header className='header-component'>
            <section className='header-component-section-first'><img src={whatsapp} alt="WhatsApp" /></section>
            <section className='header-component-section-second'><Link to='/'><img src={logocenter} alt="Logo Center" /></Link></section>
            <section className='header-component-section-third'>
                <select name="language" value={language} onChange={handleLanguageChange}>
                    <option value="en">English</option>
                    <option value="ar">العربية</option>
                </select>

                <Link to='/cart'>
                    <img src={bucket} alt="Cart" />
                    <div>{totalItems}</div>
                </Link>
            </section>
        </header>
    );
}

export default HeaderComponent;
