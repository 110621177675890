import React from 'react';

import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';
import UnderHeaderComponent from '../components/UnderHeaderComponent';
import MainDairyPage from '../components/MainDairyPage';

function DairyPage() {
    return (
        <div>
            <HeaderComponent />
            <UnderHeaderComponent />
            <MainDairyPage />
            <FooterComponent />
        </div>
    );
}

export default DairyPage;
