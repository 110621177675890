import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from '../components/CartContext';
import { LanguageContext } from './LanguageContext';
import arrow from '../images/arrowproduct.png';
import plus from '../images/buttonplus.png';
import minus from '../images/buttonminus.png';
import cross from '../images/cross.png';
import notification from '../images/notification.png';

function CartMainComponent() {
    const { cart, addToCart, removeFromCart, clearCart, deleteFromCart, incrementProductQuantity } = useContext(CartContext);
    const { language } = useContext(LanguageContext);

    const translations = {
        en: {
            home: "Home",
            myCart: "My Cart",
            items: "items",
            sorry: "Sorry!",
            emptyCart: "There are no items in your cart.",
            startShopping: "Start Shopping",
            estimatedTotal: "Estimated Total",
            taxCheckout: "Tax calculated in checkout",
            total: "Total",
            checkout: "CHECKOUT",
            clearCart: "Clear Cart",
            tax: "5%",
            decreaseQuantity: "Decrease quantity",
            increaseQuantity: "Increase quantity",
            removeItem: "Remove item"
        },
        ar: {
            home: "الصفحة الرئيسية",
            myCart: "عربة التسوق الخاصة بي",
            items: "عناصر",
            sorry: "عذرا!",
            emptyCart: "لا توجد عناصر في عربة التسوق الخاصة بك.",
            startShopping: "ابدأ التسوق",
            estimatedTotal: "الإجمالي المقدر",
            taxCheckout: "تم احتساب الضريبة في السداد",
            total: "المجموع",
            checkout: "الدفع CHECKOUT",
            clearCart: "إفراغ العربة",
            tax: "5%",
            decreaseQuantity: "تقليل الكمية",
            increaseQuantity: "زيادة الكمية",
            removeItem: "إزالة العنصر"
        }
    };

    const t = translations[language] || translations.en;

    const totalPrice = cart.reduce((total, item) => {
        const salePercentageValue = parseFloat(item.salePercentage) / 100;
        const originalPriceValue = parseFloat(item.originalPrice.replace('AED ', ''));
        const salePriceValue = originalPriceValue + salePercentageValue * originalPriceValue;
        return total + salePriceValue * (item.quantity || 1);
    }, 0);

    // const tax = totalPrice * 0.05;
    const tax = 0;
    const finalTotal = totalPrice + tax;

    return (
        <div className='cart-component'>
            <header>
                <Link to='/'>{t.home}</Link>
                <img src={arrow} alt="arrow" />
                <span>{`${t.myCart} (${cart.length} ${t.items})`}</span>
            </header>
            {cart.length === 0 ? (
                <div className='empty-cart'>
                    <div className='empty-cart-div'>
                        <img src={notification} alt="notification" />
                        <h2>{t.sorry}</h2>
                        <h2>{t.emptyCart}</h2>
                        <Link to='/'>{t.startShopping}</Link>
                    </div>
                </div>
            ) : (
                <div className='div-cart-list'>
                    <div className='cart-list'>
                        {cart.map(item => {
                            const salePercentageValue = parseFloat(item.salePercentage) / 100;
                            const originalPriceValue = parseFloat(item.originalPrice.replace('AED ', ''));
                            const salePriceValue = originalPriceValue + salePercentageValue * originalPriceValue;
                            return (
                                <div className='cart-product' key={item.id || Math.random()}>
                                    <div className='h2-img-product'>
                                        <img src={item.image} className='cart-product-image' alt={item.name} />
                                        <h2>{item.name}</h2>
                                    </div>
                                    <div className={`calculate-product ${item.name === "Oasis Zero Sodium" ? 'center-content' : ''}`}>
                                        {item.name === "Oasis Zero Sodium" ? <></> : <img
                                            src={minus}
                                            onClick={() => item.quantity > 1 && removeFromCart(item)}
                                            alt={t.decreaseQuantity}
                                        />}
                                        <span>{item.quantity}</span>
                                        {item.name === "Oasis Zero Sodium" ? <></> :
                                            <img src={plus}
                                                onClick={() => incrementProductQuantity(item)}
                                                alt={t.increaseQuantity} />}
                                    </div>
                                    {salePercentageValue < 0 ? (
                                        <div className='price-div-cart-main'>
                                            <p className='price-cart-main'>AED {(originalPriceValue * item.quantity).toFixed(2)}</p>
                                            <p>{item.salePercentage}</p>
                                            <p className='price-sale-cart-main'>AED {(salePriceValue * item.quantity).toFixed(2)}</p>
                                        </div>
                                    ) :
                                        (
                                            <div className='price-div-cart-main'><p className='price-sale-cart-main'>AED {(salePriceValue * item.quantity).toFixed(2)}</p></div>
                                        )}
                                    <img src={cross} className='cross' onClick={() => deleteFromCart(item)} alt={t.removeItem} />
                                </div>
                            );
                        })}
                    </div>
                    <div className='total-cart'>
                        <div>
                            <h2>{t.estimatedTotal}</h2>
                            <h2>AED {totalPrice.toFixed(2)}</h2>
                        </div>
                        <div className='border-total-cart'>
                            {/* <p>{t.taxCheckout}</p>
                            <h2>{t.tax}</h2> */}
                        </div>
                        <div>
                            <h2>{t.total}</h2>
                            <h2>AED {finalTotal.toFixed(2)}</h2>
                        </div>
                        <Link to={`/check-out?price=${finalTotal.toFixed(2)}`}>
                            <button className='check-out-button'>{t.checkout}</button>
                        </Link>
                        <a className='clear-cart' onClick={clearCart}>
                            {t.clearCart}
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CartMainComponent;
