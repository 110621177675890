import React from 'react';

import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';
import UnderHeaderComponent from '../components/UnderHeaderComponent';
import CheckOutComponent from '../components/CheckOutComponent';

function CheckOutPage() {
    return (
        <div>
            <HeaderComponent />
            <UnderHeaderComponent />
            <CheckOutComponent />
            <FooterComponent />
        </div>
    );
}

export default CheckOutPage;
