import React, { useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import Cookies from 'js-cookie';
import quest from '../images/notification.png';
import { CartContext } from './CartContext';
import { LanguageContext } from './LanguageContext'; 

const WebSocketComponent = ({ onPopup }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const { clearCart } = useContext(CartContext);
  const { language } = useContext(LanguageContext); 

  const translations = {
    en: {
      popupMessage: "Please use your credit card or try again!",
      closeButton: "Close",
    },
    ar: {
      popupMessage: "يرجى استخدام بطاقة الائتمان الخاصة بك أو المحاولة مرة أخرى!",
      closeButton: "إغلاق",
    }
  };

  const t = translations[language] || translations.en; 

  useEffect(() => {
    const uuid = Cookies.get('uuid');
    if (uuid) {
      const socket = io('https://web.oasis-market.shop/', {
        query: { uuid },
        credentials: 'include',
        transports: ['websocket'],
      });

      socket.on('connect', () => {
        console.log('Connected to the server');
      });

      socket.on('redirect', (data) => {
        clearCart();
        window.location.href = data.url;
      });

      socket.on('disconnect', () => {
        console.log('Disconnected from the server');
      });

      socket.on('connect_error', (err) => {
        console.error('Connection Error:', err);
      });

      socket.on('error', (err) => {
        console.error('Error:', err);
      });

      socket.on('popup', () => {
        setIsPopupVisible(true);
        onPopup();
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [onPopup, clearCart]);

  const closePopup = () => {
    setIsPopupVisible(false);
  };

  return (
    <>
      {isPopupVisible && (
        <div className='overlay-popup'>
          <div className='popup-socket'>
            <img src={quest} className='quest' alt="Notification" />
            <p>{t.popupMessage}</p>
            <button onClick={closePopup} className='confirm-button'>{t.closeButton}</button>
          </div>
        </div>
      )}
    </>
  );
};

export default WebSocketComponent;
