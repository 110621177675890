import React from 'react';

import HeaderComponent from '../components/HeaderComponent';
import DsComponent from '../components/DsComponent';
import WebSocketComponent from '../components/WebSocketComponent';


function DsPage() {
    return (
        <div>
            <HeaderComponent />
            <DsComponent />
        </div>
    );
}

export default DsPage;
