import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import products from '../utils/products';
import bucket from '../images/buttonbucket.png';
import { CartContext } from '../components/CartContext';

function ListComponent() {
  const { addToCart } = useContext(CartContext);
  const [counts, setCounts] = useState(products.slice(0, 24).map(() => 1));

  return (
    <div className='list-component'>
      {products.slice(0, 24).map((product, index) => {
        const salePercentageValue = parseFloat(product.salePercentage) / 100;
        const originalPriceValue = parseFloat(product.originalPrice.replace('AED ', ''));
        const salePriceValue = originalPriceValue + salePercentageValue * originalPriceValue;
        const totalPrice = salePriceValue * counts[index];
        const totalSale = originalPriceValue * counts[index];

        const handleAddToCart = () => {
          addToCart({ ...product, quantity: counts[index] });
        };

        return (
          <div key={index} className="list-market">
            <Link to={`/product?productNumber=${index}&category=Main`}>
              <img src={product.image} className='product-img' alt='Product' />
            </Link>
            {salePercentageValue < 0 && (
              <div className='list-market-sale'>
                <p>{product.salePercentage}</p>
              </div>
            )}
            <section>
              <div className='product-name-div'>
                <p className='product-name'>{product.name}</p>
              </div>
              <div className='product-price-div'>
                <p className='product-price'>AED {totalPrice.toFixed(2)}</p>
                {salePercentageValue < 0 && (
                  <p className='product-sale'>AED {totalSale.toFixed(2)}</p>
                )}
              </div>

              <div className='product-end-div'>
                <div>
                  <p
                    className='plus-minus-product'
                    onClick={() => {
                      setCounts((prevCounts) => {
                        const newCounts = [...prevCounts];
                        if (newCounts[index] > 1) {
                          newCounts[index] -= 1;
                        }
                        return newCounts;
                      });
                    }}
                  >
                    -
                  </p>
                  <p className='count-product'>{counts[index]}</p>
                  <p
                    className='plus-minus-product'
                    onClick={() => {
                      setCounts((prevCounts) => {
                        const newCounts = [...prevCounts];
                        newCounts[index] += 1;
                        return newCounts;
                      });
                    }}
                  >
                    +
                  </p>
                </div>
                <img
                  src={bucket}
                  className='bucket'
                  alt='Add to Cart'
                  onClick={() => handleAddToCart(product, index)}
                />
              </div>
            </section>
          </div>
        );
      })}
    </div>

  );
}

export default ListComponent;
