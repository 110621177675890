import React from 'react';

import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';
import UnderHeaderComponent from '../components/UnderHeaderComponent';
import MainJuicePage from '../components/MainJuicePage';

function JuicePage() {
    return (
        <div>
            <HeaderComponent />
            <UnderHeaderComponent />
            <MainJuicePage/>
            <FooterComponent />
        </div>
    );
}

export default JuicePage;
