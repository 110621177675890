import React, { useContext } from 'react';
import confirm from '../images/confirm.png';
import { LanguageContext } from './LanguageContext'; 

function ConfirmComponent() {
    const { language } = useContext(LanguageContext);

    const translations = {
        en: {
            message: "The payment has been confirmed, and we are preparing for delivery.\nPlease expect it at the provided address."
        },
        ar: {
            message: "تم تأكيد الدفع، ونحن نعمل على التحضير للتسليم.\nيرجى توقعه في العنوان المقدم."
        }
    };

    const t = translations[language] || translations.en;

    return (
        <div className='confirmed-component'>
            <img src={confirm} alt="Confirmation" />
            <h1>{t.message.split('\n').map((line, index) => (
                <span key={index}>{line}<br /></span>
            ))}</h1>
        </div>
    );
}

export default ConfirmComponent;
