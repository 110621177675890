import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import '../src/pages/Main/Animations.css';
import '../src/pages/Main/fonts.css';
import '../src/pages/Main/Main.css';
import '../src/pages/Main/MainMobile.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />,
);
