import React, { useContext, useState, useEffect } from 'react';
import search from '../images/search.png';
import products from '../utils/products';
import bucket from '../images/buttonbucket.png';
import { Link, useLocation } from 'react-router-dom';
import { CartContext } from '../components/CartContext';

function MainDairyPage() {
    const { addToCart } = useContext(CartContext);
    const location = useLocation(); // Получаем текущий URL
    const queryParams = new URLSearchParams(location.search); // Извлекаем параметры из строки запроса
    const searchParam = queryParams.get('search') || ''; // Получаем значение параметра search

    const productPrices = products.slice(83, 100).map(product =>
        parseFloat(product.originalPrice.replace('AED ', ''))
    );

    const minProductPrice = Math.min(...productPrices);
    const maxProductPrice = Math.max(...productPrices);

    const [counts, setCounts] = useState(products.slice(83, 100).map(() => 1));
    const [searchTerm, setSearchTerm] = useState(searchParam); // Инициализируем поисковый запрос значением из URL
    const [minPrice, setMinPrice] = useState(minProductPrice);
    const [maxPrice, setMaxPrice] = useState(maxProductPrice);

    useEffect(() => {
        // Обновляем поисковую строку при изменении параметра в URL
        setSearchTerm(searchParam);
    }, [searchParam]);

    const handleClearFilters = () => {
        setSearchTerm('');
        setMinPrice(minProductPrice);
        setMaxPrice(maxProductPrice);
    };

    const filteredProducts = products.slice(83, 100).filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        parseFloat(product.originalPrice.replace('AED ', '')) >= minPrice &&
        parseFloat(product.originalPrice.replace('AED ', '')) <= maxPrice
    );

    const minPercentage = ((minPrice - minProductPrice) / (maxProductPrice - minProductPrice)) * 100;
    const maxPercentage = ((maxPrice - minProductPrice) / (maxProductPrice - minProductPrice)) * 100;

    return (
        <main className='main-water-page-component'>
            <section className='section-search-filter'>
                <input
                    placeholder='Search in Dairy...'
                    className='input-search'
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <img src={search} alt='Search Icon' />
                <div className='main-water-filter'>
                    <div>
                        <h1>FILTER BY</h1>
                        <span onClick={handleClearFilters} >clear all</span>
                    </div>
                    <p>Price ( AED )</p>
                    <div className="price-input">
                        <div className="field">
                            <span>Min</span>
                            <input
                                type="number"
                                className="input-min"
                                min={minProductPrice}
                                max={maxPrice}
                                value={minPrice}
                                onChange={(e) => {
                                    const newMinPrice = Math.min(maxPrice, Math.max(minProductPrice, parseInt(e.target.value) || minProductPrice));
                                    setMinPrice(newMinPrice);
                                }}
                            />
                        </div>
                        <div className="separator">-</div>
                        <div className="field">
                            <span>Max</span>
                            <input
                                type="number"
                                className="input-max"
                                min={minPrice}
                                max={maxProductPrice}
                                value={maxPrice}
                                onChange={(e) => {
                                    const newMaxPrice = Math.max(minPrice, Math.min(maxProductPrice, parseInt(e.target.value) || maxProductPrice));
                                    setMaxPrice(newMaxPrice);
                                }}
                            />
                        </div>
                    </div>
                    <div className="slider">
                        <div className="progress" style={{ left: `${minPercentage}%`, width: `${maxPercentage - minPercentage}%` }}></div>
                    </div>
                    <div className="range-input">
                        <input type="range" className="range-min" min={minProductPrice} max={maxProductPrice} value={minPrice} step="1" onChange={(e) => setMinPrice(parseInt(e.target.value))} />
                        <input type="range" className="range-max" min={minProductPrice} max={maxProductPrice} value={maxPrice} step="1" onChange={(e) => setMaxPrice(parseInt(e.target.value))} />
                    </div>
                </div>
            </section>

            <section className='second-section-list-water'>
                <div className='list-component list-component-mobile'>
                    {filteredProducts.map((product, index) => {
                        const salePercentageValue = parseFloat(product.salePercentage) / 100;
                        const originalPriceValue = parseFloat(product.originalPrice.replace('AED ', ''));
                        const salePriceValue = originalPriceValue + (salePercentageValue * originalPriceValue);
                        const totalPrice = salePriceValue * counts[index];
                        const totalSale = originalPriceValue * counts[index];

                        const handleAddToCart = () => {
                            addToCart({ ...product, quantity: counts[index] });
                        };
                        return (
                            <div key={index} className='list-market list-market-mobile'>
                                <Link to={`/product?productNumber=${index + 83}&category=Dairy`}>
                                    <img src={product.image} className='product-img' alt='Product' />
                                </Link>
                                {salePercentageValue < 0 && (
                                    <div className='list-market-sale'>
                                        <p>{product.salePercentage}</p>
                                    </div>
                                )}
                                <section>
                                <div className='product-name-div product-name-div-mobile'>
                                        <p className='product-name'>
                                            {product.name}
                                        </p>
                                    </div>
                                    <div className='product-price-div product-price-div-mobile'>
                                        <p className='product-price'>AED {totalPrice.toFixed(2)}</p>
                                        {salePercentageValue < 0 && (<p className='product-sale'>AED {totalSale.toFixed(2)}</p>)}
                                    </div>

                                    <div className='product-end-div product-end-div-mobile'>
                                        <div>
                                            <p
                                                className='plus-minus-product plus-minus-product-mobile'
                                                onClick={() => {
                                                    setCounts(prevCounts => {
                                                        const newCounts = [...prevCounts];
                                                        if (newCounts[index] > 1) {
                                                            newCounts[index] -= 1;
                                                        }
                                                        return newCounts;
                                                    });
                                                }}
                                            >
                                                -
                                            </p>
                                            <p className='count-product'>{counts[index]}</p>
                                            <p
                                                className='plus-minus-product plus-minus-product-mobile'
                                                onClick={() => {
                                                    setCounts(prevCounts => {
                                                        const newCounts = [...prevCounts];
                                                        newCounts[index] += 1;
                                                        return newCounts;
                                                    });
                                                }}
                                            >
                                                +
                                            </p>
                                        </div>
                                        <img
                                            src={bucket}
                                            className='bucket bucket-mobile'
                                            alt='Add to Cart'
                                            onClick={() => handleAddToCart(product, index)}
                                        />
                                    </div>
                                </section>
                            </div>
                        );
                    })}
                </div>
            </section>
        </main>
    );
}

export default MainDairyPage;
