import React from 'react';

import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';
import UnderHeaderComponent from '../components/UnderHeaderComponent';
import MainWaterPage from '../components/MainWaterPage';

function WaterPage() {
    return (
        <div>
            <HeaderComponent />
            <UnderHeaderComponent />
            <MainWaterPage/>
            <FooterComponent />
        </div>
    );
}

export default WaterPage;
