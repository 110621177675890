import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import image1 from '../images/underheader/water.png'
import image2 from '../images/underheader/juice.png'
import image3 from '../images/underheader/dairy.png'
import image4 from '../images/underheader/acsesour.png'
import { LanguageContext } from './LanguageContext';

function UnderHeaderComponent() {
    const { language } = useContext(LanguageContext);

    const translations = {
        en: {
            water: "Water",
            juice: "Juice",
            dairy: "Dairy",
            accessories: "Accessories"
        },
        ar: {
            water: "ماء",
            juice: "عصير",
            dairy: "منتجات الألبان",
            accessories: "إكسسوارات"
        },
    };

    const t = translations[language] || translations.en;

    return (
        <div className='under-header-component-first-div'>
            <div className='under-header-component'>
                <div>
                    <Link to="/water"><img src={image1} alt={t.water} />{t.water}</Link>
                    <Link to="/juice"><img src={image2} alt={t.juice} />{t.juice}</Link>
                    <Link to="/dairy"><img src={image3} alt={t.dairy} />{t.dairy}</Link>
                    <Link to="/accessories"><img src={image4} alt={t.accessories} />{t.accessories}</Link>
                </div>
            </div>
        </div>
    );
}

export default UnderHeaderComponent;
