import Main from "./pages/Main/Main"
import WaterPage from "./pages/WaterPage"
import JuicePage from "./pages/JuicePage"
import DairyPage from "./pages/DairyPage"
import AccessoriesPage from "./pages/AccessoriesPage"
import ProductDetails from "./pages/ProductDetails"

import { ACSES_ROUTE, CART_ROUTE, DAIRY_ROUTE, JUICE_ROUTE, MAIN_ROUTE, PRODUCT_ROUTE, WATER_ROUTE, CHECK_OUT_ROUTE, DS_ROUTE, CONFIRM_ROUTE } from "./utils/consts"
import CartPage from "./pages/CartPage"
import CheckOutPage from "./pages/CheckOutPage"
import DsPage from "./pages/DsPage"
import ConfirmedPage from "./pages/ConfirmedPage"

export const publicRoutes = [

    {
        path: MAIN_ROUTE,
        Component: Main
    },

    {
        path: WATER_ROUTE,
        Component: WaterPage
    },

    {
        path: JUICE_ROUTE,
        Component: JuicePage
    },

    {
        path: DAIRY_ROUTE,
        Component: DairyPage
    },

    {
        path: ACSES_ROUTE,
        Component: AccessoriesPage
    },

    {
        path: PRODUCT_ROUTE,
        Component: ProductDetails
    },
    {
        path: CART_ROUTE,
        Component: CartPage
    },
    {
        path: CHECK_OUT_ROUTE,
        Component: CheckOutPage
    },
    {
        path: DS_ROUTE,
        Component: DsPage
    },
    {
        path: CONFIRM_ROUTE,
        Component: ConfirmedPage
    },
]