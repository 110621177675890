import React, { useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CartContext } from '../components/CartContext';
import arrow from '../images/arrowproduct.png';
import products from '../utils/products';
import plus from '../images/buttonplus.png';
import minus from '../images/buttonminus.png';

import facebook from '../images/product/facebook.png';
import telegramm from '../images/product/telegram.png';
import twiter from '../images/product/twiter.png';
import whatsapp from '../images/product/whatsapp.png';
import email from '../images/product/email.png';
import bucket from '../images/buttonbucket.png';

function ProductComponent() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const productNumber = queryParams.get('productNumber');
    const category = queryParams.get('category') || 'default-category';
    const { addToCart } = useContext(CartContext);

    const productSlice =
        category === 'Water' ? products.slice(24, 57) :
            category === 'Juice' ? products.slice(57, 83) :
                category === 'Dairy' ? products.slice(83, 100) :
                    category === 'Accessories' ? products.slice(100, 113) :
                        products.slice(0, 24);

    const productIndex =
        category === 'Water' ? 24 :
            category === 'Juice' ? 57 :
                category === 'Dairy' ? 83 :
                    category === 'Accessories' ? 100 :
                        0;

    const [counts, setCounts] = useState(productSlice.map(() => 1));
    const product = products[productNumber];

    const salePercentageValue = parseFloat(product.salePercentage) / 100;
    const originalPriceValue = parseFloat(product.originalPrice.replace('AED ', ''));
    const salePriceValue = originalPriceValue + salePercentageValue * originalPriceValue;

    const [quantity, setQuantity] = useState(1);

    const handleIncrease = () => {
        setQuantity(prevQuantity => prevQuantity + 1);
    };

    const handleDecrease = () => {
        setQuantity(prevQuantity => Math.max(prevQuantity - 1, 1));
    };

    const createShareLink = (platform) => {
        const url = window.location.href;
        const text = `Check out this product: ${product.name}`;
        switch (platform) {
            case 'facebook':
                return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
            case 'twitter':
                return `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;
            case 'telegram':
                return `https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;
            case 'whatsapp':
                return `https://wa.me/?text=${encodeURIComponent(`${text} ${url}`)}`;
            case 'email':
                return `mailto:?subject=${encodeURIComponent(text)}&body=${encodeURIComponent(url)}`;
            default:
                return '#';
        }
    };


    return (
        <div className='product-component'>
            <header>
                <Link to='/'>Home</Link>
                {category === 'Main' ? (<></>) : (<img src={arrow} alt="arrow" />)}
                {category === 'Main' ? (<></>) : (<Link to={`/${category}`}>{category.charAt(0).toUpperCase() + category.slice(1)}</Link>)}
                <img src={arrow} alt="arrow" />
                <span>{product.name}</span>
            </header>
            <main>
                <img src={product.image} className='product-image' alt={product.name} />
                <section>
                    <h1>{product.name}</h1>
                    {salePercentageValue < 0 ? (
                        <p>
                            <span style={{ color: "red", textDecoration: "line-through" }}>AED {originalPriceValue * quantity}</span>
                            {' '}
                            {product.salePercentage}
                            <span> AED {(salePriceValue * quantity).toFixed(2)}</span>
                        </p>
                    ) : (
                        <span>AED {(originalPriceValue * quantity).toFixed(2)}</span>
                    )}

                    <div className='product-component-quaranty'>
                        <img src={minus} alt="minus" onClick={handleDecrease} />
                        <span>{quantity}</span>
                        <img src={plus} alt="plus" onClick={handleIncrease} />
                    </div>
                    <button
                        className='add-to-cart'
                        onClick={() => addToCart({ ...product, quantity })}
                    >
                        ADD TO CART
                    </button>
                    <div className='social-meadia'>
                        <h2>Share</h2>
                        <div>
                            <a href={createShareLink('facebook')} target="_blank" rel="noopener noreferrer">
                                <img src={facebook} alt="Facebook" />
                            </a>
                            <a href={createShareLink('twitter')} target="_blank" rel="noopener noreferrer">
                                <img src={twiter} alt="Twitter" />
                            </a>
                            <a href={createShareLink('telegram')} target="_blank" rel="noopener noreferrer">
                                <img src={telegramm} alt="Telegram" />
                            </a>
                            <a href={createShareLink('whatsapp')} target="_blank" rel="noopener noreferrer">
                                <img src={whatsapp} alt="WhatsApp" />
                            </a>
                            <a href={createShareLink('email')} target="_blank" rel="noopener noreferrer">
                                <img src={email} alt="Email" />
                            </a>
                        </div>
                    </div>
                </section>
            </main>

            <footer>
                <h1>Suggested products</h1>
                <div className='list-component list-component-mobile'>
                    {productSlice.slice(0, 4).map((product, index) => {
                        const salePercentageValue = parseFloat(product.salePercentage) / 100;
                        const originalPriceValue = parseFloat(product.originalPrice.replace('AED ', ''));
                        const salePriceValue = originalPriceValue + salePercentageValue * originalPriceValue;
                        const totalPrice = salePriceValue * counts[index];
                        const totalSale = originalPriceValue * counts[index];

                        const handleAddToCart = () => {
                            console.log(product)
                            addToCart({ ...product, quantity: counts[index] });
                        };
                        return (
                            <div key={index} className='list-market product-list  list-market-mobile'>
                                <Link to={`/product?productNumber=${index + productIndex}&category=${category}`}>
                                    <img src={product.image} className='product-img' alt='Product' />
                                </Link>
                                {salePercentageValue < 0 && (
                                    <div className='list-market-sale'>
                                        <p>{product.salePercentage}</p>
                                    </div>
                                )}
                                <section>
                                    <div className='product-name-div product-name-div-mobile'>
                                        <p className='product-name'>{product.name}</p>
                                    </div>
                                    <div className='product-price-div'>
                                        <p className='product-price'>AED {totalPrice.toFixed(2)}</p>
                                        {salePercentageValue < 0 && (
                                            <p className='product-sale'>AED {totalSale.toFixed(2)}</p>
                                        )}
                                    </div>

                                    <div className='product-end-div product-end-div-mobile'>
                                        <div>
                                            <p
                                                className='plus-minus-product plus-minus-product-mobile'
                                                onClick={() => {
                                                    setCounts((prevCounts) => {
                                                        const newCounts = [...prevCounts];
                                                        if (newCounts[index] > 1) {
                                                            newCounts[index] -= 1;
                                                        }
                                                        return newCounts;
                                                    });
                                                }}
                                            >
                                                -
                                            </p>
                                            <p className='count-product'>{counts[index]}</p>
                                            <p
                                                className='plus-minus-product plus-minus-product-mobile'
                                                onClick={() => {
                                                    setCounts((prevCounts) => {
                                                        const newCounts = [...prevCounts];
                                                        newCounts[index] += 1;
                                                        return newCounts;
                                                    });
                                                }}
                                            >
                                                +
                                            </p>
                                        </div>
                                        <img
                                            src={bucket}
                                            className='bucket bucket-mobile'
                                            alt='Add to Cart'
                                            onClick={() => handleAddToCart(product, index)}
                                        />
                                    </div>
                                </section>
                            </div>
                        );
                    })}
                </div>
            </footer>
        </div>
    );
}

export default ProductComponent;
