import React from 'react';


import HeaderComponent from '../../components/HeaderComponent';
import FooterComponent from '../../components/FooterComponent';
import UnderHeaderComponent from '../../components/UnderHeaderComponent';
import BannerComponent from '../../components/BannerComponent';
import UnderBannerComponent from '../../components/UnderBannerComponent';
import ListComponent from '../../components/ListComponent';
import StartBannerComponent from '../../components/StartBannerComponent';

function Main() {
    return (
        <div>
            <StartBannerComponent/>
            <HeaderComponent />
            <UnderHeaderComponent />
            <BannerComponent />
            <UnderBannerComponent />
            <ListComponent />
            <FooterComponent />
        </div>
    );
}

export default Main;
