import React from 'react';

import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';
import UnderHeaderComponent from '../components/UnderHeaderComponent';
import CartMainComponent from '../components/CartMainComponent';

function CartPage() {
    return (
        <div>
            <HeaderComponent />
            <UnderHeaderComponent />
            <CartMainComponent />
            <FooterComponent />
        </div>
    );
}

export default CartPage;
