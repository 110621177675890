import React from 'react';
import Slider from 'react-slick';

import banner from '../images/banner.png';
import banner2 from '../images/banner2.png';
import banner3 from '../images/banner3.png';
import banner4 from '../images/banner4.png';
import { Link } from 'react-router-dom';

function BannerComponent() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    return (
        <div className='banner-component'>
            <Slider {...settings}>
                <div>
                <Link to='/water'>  <img src={banner} alt="Banner 1" /></Link>
                </div>
                <div>
                <Link to='/water?search=oasis'> <img src={banner2} alt="Banner 2" /></Link>
                </div>
                <div>
                <Link to='/water?search=zero'><img src={banner3} alt="Banner 3" /></Link>
                </div>
                <div>
                <Link to='/dairy?search=lacnor'> <img src={banner4} alt="Banner 4" /></Link>
                </div>
            </Slider>
        </div>
    );
}

export default BannerComponent;
