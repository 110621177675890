import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import visamaster from '../images/visa-master.png';
import { LanguageContext } from './LanguageContext';

function FooterComponent() {
    const { language } = useContext(LanguageContext);

    const translations = {
        en: {
            collections: "COLLECTIONS",
            water: "Water",
            juice: "Juice",
            dairy: "Dairy",
            accessories: "Accessories",
            information: "INFORMATION",
            oasisWebsite: "Our Oasis Website",
            aboutUs: "About Us",
            contactUs: "Contact Us",
            terms: "Terms And Conditions",
            privacyPolicy: "Privacy Policy",
            faqs: "FAQs"
        },
        ar: {
            collections: "المجموعات",
            water: "المياه",
            juice: "عصير",
            dairy: "الألبان",
            accessories: "ملحقات",
            information: "معلومات",
            oasisWebsite: "موقع الواحة الخاص بنا",
            aboutUs: "معلومات عنا",
            contactUs: "اتصل بنا",
            terms: "الشروط والأحكام",
            privacyPolicy: "سياسة الخصوصية",
            faqs: "الأسئلة الشائعة"
        }
    };

    const t = translations[language] || translations.en;

    return (
        <footer className='footer-component'>
            <main>
                <section>
                    <h1>{t.collections}</h1>
                    <Link to='/water'><p>{t.water}</p></Link>
                    <Link to='/Juice'><p>{t.juice}</p></Link>
                    <Link to='/Dairy'><p>{t.dairy}</p></Link>
                    <Link to='/Accessories'><p>{t.accessories}</p></Link>
                </section>
                <section>
                    <h1>{t.information}</h1>
                    <p>{t.oasisWebsite}</p>
                    <p>{t.aboutUs}</p>
                    <p>{t.contactUs}</p>
                    <p>{t.terms}</p>
                    <p>{t.privacyPolicy}</p>
                    <p>{t.faqs}</p>
                </section>
            </main>
        </footer>
    );
}

export default FooterComponent;
