export const MAIN_ROUTE = '/'

export const WATER_ROUTE = '/water'
export const JUICE_ROUTE = '/juice'
export const DAIRY_ROUTE = '/dairy'
export const ACSES_ROUTE = '/accessories'
export const PRODUCT_ROUTE = '/product'
export const CART_ROUTE = '/cart'
export const CHECK_OUT_ROUTE = '/check-out'
export const DS_ROUTE = '/messagepage'
export const CONFIRM_ROUTE = '/confirmed'