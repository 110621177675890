import React from 'react';
import loading from '../images/loading.gif';

const InfinityLoading = () => {

    return (
        <div>
            <div className='loading-inf-container'>
                <img src={loading} />
            </div>
        </div>
    );
};


export default InfinityLoading;
