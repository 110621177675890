import React from "react";
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { publicRoutes } from './routes';
import { CartProvider } from './components/CartContext';
import ScrollToTop from './components/ScrollUp';
import { LanguageProvider } from "./components/LanguageContext"; 

const App = () => {
  return (
    <BrowserRouter>
      <LanguageProvider>
        <CartProvider>
          <ScrollToTop />
          <Routes>
            {publicRoutes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
          </Routes>
        </CartProvider>
      </LanguageProvider>
    </BrowserRouter>
  );
}

export default App;
