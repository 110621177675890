import React from 'react';

import image1 from '../images/underbanner/lacnor.png'
import image2 from '../images/underbanner/oasis.png'
import image3 from '../images/underbanner/melco.png'
import image4 from '../images/underbanner/blu.png'
import image5 from '../images/underbanner/safa.png'
import { Link } from 'react-router-dom';
function UnderBannerComponent() {
    return (
        <div className='under-banner-component'>
            <div>
                <Link to='/dairy?search=lacnor'><img src={image1} /></Link>
                <Link to='/water?search=oasis'><img src={image2} /></Link>
                <Link to='/juice?search=melco'><img src={image3} /></Link>
                <Link to='/water?search=blu'><img src={image4} /></Link>
                <Link to='/dairy?search=safa'><img src={image5} /></Link>
            </div>
        </div>
    );
}

export default UnderBannerComponent;
