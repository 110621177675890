import React, { useState, useEffect, useContext } from 'react';

import banner from '../images/background.png';
import cross from '../images/cross.png';
import products from '../utils/products';
import { CartContext } from './CartContext';

function StartBannerComponent() {
    const [isVisible, setIsVisible] = useState(false);
    const { addToCart, deleteFromCart } = useContext(CartContext);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    const handleClose = () => {
        setIsVisible(false);
    };

    const handleAddWater = () => {
        const productToAdd = { ...products[113], quantity: 3 };
        deleteFromCart(productToAdd);
        addToCart(productToAdd);
        setIsVisible(false);
    };

    return (
        <>
            {isVisible && (
                <main className='overlay'>
                    <div>
                        <img src={banner} className='banner-overlay' alt="Banner" onClick={handleAddWater} />
                        <img
                            src={cross}
                            className='cross-overlay'
                            alt="Close"
                            onClick={handleClose}
                        />
                    </div>
                </main>
            )}
        </>
    );
}

export default StartBannerComponent;
