import image1 from '../images/marketimg/page1.jpg'
import image2 from '../images/marketimg/page2.jpg'
import image3 from '../images/marketimg/image3.jpg'
import image4 from '../images/marketimg/image4.jpg'
import image5 from '../images/marketimg/image5.jpg'
import image6 from '../images/marketimg/image6.jpg'
import image7 from '../images/marketimg/image7.png'
import image8 from '../images/marketimg/image8.jpg'
import image9 from '../images/marketimg/image9.jpg'
import image10 from '../images/marketimg/image10.jpg'
import image11 from '../images/marketimg/image11.jpg'
import image12 from '../images/marketimg/image12.jpg'
import image13 from '../images/marketimg/image13.jpg'
import image14 from '../images/marketimg/image14.jpg'
import image15 from '../images/marketimg/image15.jpg'
import image16 from '../images/marketimg/image16.jpg'
import image17 from '../images/marketimg/image17.jpg'
import image18 from '../images/marketimg/image18.jpg'
import image19 from '../images/marketimg/image19.jpg'
import image21 from '../images/marketimg/image21.jpg'
import image22 from '../images/marketimg/image22.jpg'
import image23 from '../images/marketimg/image23.jpg'
import image24 from '../images/marketimg/image24.jpg'
import image25 from '../images/marketimg/image25.jpg'
import image26 from '../images/marketimg/image26.jpg'
import image27 from '../images/marketimg/image27.jpg'
import image28 from '../images/marketimg/image28.jpg'
import image29 from '../images/marketimg/image29.jpg'
import image30 from '../images/marketimg/image30.jpg'
import image31 from '../images/marketimg/image31.jpg'
import image32 from '../images/marketimg/image32.jpg'
import image33 from '../images/marketimg/image33.jpg'
import image34 from '../images/marketimg/image34.jpg'
import image35 from '../images/marketimg/image35.jpg'
import image36 from '../images/marketimg/image36.jpg'
import image37 from '../images/marketimg/image37.jpg'
import image38 from '../images/marketimg/image38.jpg'
import image39 from '../images/marketimg/image39.jpg'
import image40 from '../images/marketimg/image40.jpg'
import image41 from '../images/marketimg/image41.jpg'
import image42 from '../images/marketimg/image42.jpg'
import image43 from '../images/marketimg/image43.jpg'
import image44 from '../images/marketimg/image44.jpg'
import image45 from '../images/marketimg/image45.jpg'
import image46 from '../images/marketimg/image46.jpg'
import image47 from '../images/marketimg/image47.jpg'
import image48 from '../images/marketimg/image48.jpg'
import image49 from '../images/marketimg/image49.jpg'
import image50 from '../images/marketimg/image50.jpg'
import image51 from '../images/marketimg/image51.jpg'
import image54 from '../images/marketimg/image54.jpg'
import image55 from '../images/marketimg/image55.jpg'
import image56 from '../images/marketimg/image56.jpg'
import image57 from '../images/marketimg/image57.jpg'
import image58 from '../images/marketimg/image58.jpg'
import image59 from '../images/marketimg/image59.jpg'
import image60 from '../images/marketimg/image60.jpg'
import image61 from '../images/marketimg/image61.jpg'
import image62 from '../images/marketimg/image62.jpg'
import image63 from '../images/marketimg/image63.jpg'
import image64 from '../images/marketimg/image64.jpg'
import image65 from '../images/marketimg/image65.jpg'
import image66 from '../images/marketimg/image66.jpg'
import image67 from '../images/marketimg/image67.jpg'
import image68 from '../images/marketimg/image68.jpg'
import image69 from '../images/marketimg/image69.jpg'
import image70 from '../images/marketimg/image70.jpg'
import image71 from '../images/marketimg/image71.jpg'
import image72 from '../images/marketimg/image72.jpg'
import image73 from '../images/marketimg/image73.jpg'
import image74 from '../images/marketimg/image74.jpg'
import image75 from '../images/marketimg/image75.jpg'
import image76 from '../images/marketimg/image76.jpg'
import image77 from '../images/marketimg/image77.jpg'
import image78 from '../images/marketimg/image78.jpg'
import image79 from '../images/marketimg/image79.jpg'
import image80 from '../images/marketimg/image80.jpg'
import image81 from '../images/marketimg/image81.jpg'
import image82 from '../images/marketimg/image82.jpg'

const products = [
    {
        image: image1,
        name: 'Hot & Cold Dispenser Sanitization Service',
        salePercentage: '-33%',
        originalPrice: 'AED 75.0',
    },
    {
        image: image2,
        name: 'Bulk Offer Oasis Still 200 ml Pack of 24 (Bundle of 4 Packs)',
        salePercentage: '-27%',
        originalPrice: 'AED 60.0',
    },
    {
        image: image2,
        name: 'Bulk Offer Oasis Still 330 ml Pack of 24 (Bundle of 4 Packs)',
        salePercentage: '-27%',
        originalPrice: 'AED 99.0',
    },
    {
        image: image2,
        name: 'Bulk Offer Oasis Still 500 ml Pack of 24 (Bundle of 4 Packs)',
        salePercentage: '-27%',
        originalPrice: 'AED 60.0',
    },

    // некст 2

    {
        image: image3,
        name: 'Bulk Offer Oasis 1.5L Carton of 12 (Bundle of 4 Cartons)',
        salePercentage: '-27%',
        originalPrice: 'AED 60.0',
    },
    {
        image: image4,
        name: 'Bulk Offer Oasis Still 200 ml Pack of 24 (Bundle of 2 Packs)',
        salePercentage: '-20%',
        originalPrice: 'AED 30.0',
    },
    {
        image: image4,
        name: 'Bulk Offer Oasis Still 330 ml Pack of 24 (Bundle of 2 Packs)',
        salePercentage: '-20%',
        originalPrice: 'AED 50.0',
    },
    {
        image: image4,
        name: 'Bulk Offer Oasis Still 500 ml Pack of 24 (Bundle of 2 Packs)',
        salePercentage: '-20%',
        originalPrice: 'AED 75.0',
    },

    // next 3


    {
        image: image5,
        name: 'Bulk Offer Oasis 1.5L Carton of 12 (Bundle of 2 Cartons)',
        salePercentage: '-20%',
        originalPrice: 'AED 75.0',
    },
    {
        image: image6,
        name: 'Bulk Offer Oasis Zero 500 ml – Pack of 12 bottles (Bundle of 4 Packs)',
        salePercentage: '-15%',
        originalPrice: 'AED 36.0',
    },
    {
        image: image7,
        name: 'Bulk Offer Oasis Zero 1.5 Litre – Pack of 6 bottles(Bundle of 4 Packs)',
        salePercentage: '-50%',
        originalPrice: 'AED 50.0',
    },
    {
        image: image8,
        name: 'Bulk Offer Lacnor Long Life Strawberry Milk 180ml Pack of 8(Bundle of 5 Outers)',
        salePercentage: '-14%',
        originalPrice: 'AED 44.0',
    },

    // next 4

    {
        image: image9,
        name: 'Bulk Offer Lacnor Long Life Chocolate Milk 180ml Pack of 8 (Bundle of 5 Outers)',
        salePercentage: '-14%',
        originalPrice: 'AED 44.0',
    },
    {
        image: image10,
        name: 'Melco Long Life Drink Mango 180ml - Carton of 27 (Bundle of 5 Cartons)',
        salePercentage: '-14%',
        originalPrice: 'AED 130.0',
    },
    {
        image: image10,
        name: 'Melco Long Life Drink Orange 180ml - Carton of 27 (Bundle of 5 Cartons)',
        salePercentage: '-14%',
        originalPrice: 'AED 130.0',
    },
    {
        image: image10,
        name: 'Melco Long Life Drink Fruit Cocktail 180ml - Carton of 27 (Bundle of 5 Cartons)',
        salePercentage: '-14%',
        originalPrice: 'AED 130.0',
    },

    // next 5


    {
        image: image11,
        name: 'Bulk Offer Lacnor Long Life Full Cream Milk 180ml Pack of 8 (Bundle of 5 Packs)',
        salePercentage: '-14%',
        originalPrice: 'AED 44.0',
    },
    {
        image: image11,
        name: 'Bulk Offer Lacnor Long Life Full Cream Milk 1L Pack of 4 (Bundle of 5 Outers)',
        salePercentage: '-14%',
        originalPrice: 'AED 90.0',
    },
    {
        image: image12,
        name: 'Bulk Offer Lacnor Healthy Living Pomegranate 1L (Bundle of 5 Pieces)',
        salePercentage: '-14%',
        originalPrice: 'AED 48.0',
    },
    {
        image: image13,
        name: 'Bulk Offer Lacnor 100 % Long Life Apple Juice 1L (Bundle of 5 Pieces)',
        salePercentage: '-14%',
        originalPrice: 'AED 38.0',
    },

    // next 6
    {
        image: image14,
        name: 'Bulk Offer Lacnor 100% Long Life Apple Juice 180ml Pack of 8 (Bundle of 5 Outers)',
        salePercentage: '-14%',
        originalPrice: 'AED 44.0',
    },
    {
        image: image15,
        name: 'Bulk Offer Lacnor 100 % Long Life Fruit Cocktail Juice 1L (Bundle of 5 Pieces)',
        salePercentage: '-14%',
        originalPrice: 'AED 38.0',
    },
    {
        image: image16,
        name: 'Oasis Zero Sodium Free 5 Gallon',
        salePercentage: '-40%',
        originalPrice: 'AED 12.0',
    },
    {
        image: image17,
        name: 'Oasis 4 Gallon',
        salePercentage: '-8%',
        originalPrice: 'AED 12.0',
    },


    // water page 

    {
        image: image16,
        name: 'Oasis Zero Sodium Free 5 Gallon',
        salePercentage: '-40%',
        originalPrice: 'AED 12.0',
    },
    {
        image: image18,
        name: 'Oasis 5 Gallon',
        salePercentage: '-9%',
        originalPrice: 'AED 11.0',
    },
    {
        image: image17,
        name: 'Oasis 4 Gallon',
        salePercentage: '-8%',
        originalPrice: 'AED 12.0',
    },
    // next 2

    {
        image: image19,
        name: 'Oasis 1 Gallon - Carton of 6',
        salePercentage: '-0%',
        originalPrice: 'AED 14.0',
    },
    {
        image: image19,
        name: 'Oasis 5L - Carton of 4',
        salePercentage: '-0%',
        originalPrice: 'AED 17.0',
    },
    {
        image: image21,
        name: 'Oasis Zero 1.5 Litre Pack of 6 bottles',
        salePercentage: '-0%',
        originalPrice: 'AED 9.0',
    },

    // next 3

    {
        image: image22,
        name: 'Oasis 1.5L - Carton of 12',
        salePercentage: '-0%',
        originalPrice: 'AED 14.0',
    },
    {
        image: image21,
        name: 'Oasis Zero 500 ml Pack of 12 bottles',
        salePercentage: '-0%',
        originalPrice: 'AED 9.0',
    },
    {
        image: image23,
        name: 'Oasis 500ml - pack of 24 bottles',
        salePercentage: '-0%',
        originalPrice: 'AED 14.0',
    },

    // next 4


    {
        image: image23,
        name: 'Oasis 200ml - Pack of 24 Bottles',
        salePercentage: '-0%',
        originalPrice: 'AED 14.0',
    },
    {
        image: image23,
        name: 'Oasis 330ml - Pack of 24 Bottles',
        salePercentage: '-0%',
        originalPrice: 'AED 14.0',
    },
    {
        image: image24,
        name: 'Oasis 200 ml Cups - Carton of 24 Cups',
        salePercentage: '-0%',
        originalPrice: 'AED 12.0',
    },


    // next 5


    {
        image: image24,
        name: 'Oasis 100 ml Cups - Carton of 54 Cups',
        salePercentage: '-0%',
        originalPrice: 'AED 14.0',
    },

    {
        image: image25,
        name: 'Blu Sparkling 1L - Pack of 6',
        salePercentage: '-0%',
        originalPrice: 'AED 22.0',
    },
    {
        image: image25,
        name: 'Blu Sparkling 500ml - Pack of 6',
        salePercentage: '-0%',
        originalPrice: 'AED 16.0',
    },

    // next
    {
        image: image25,
        name: 'Blu Sparkling 250 ml - Pack of 6',
        salePercentage: '-0%',
        originalPrice: 'AED 12.0',
    },
    {
        image: image26,
        name: 'Blu Sparkling Pineapple 500 ml - Pack of 6',
        salePercentage: '-0%',
        originalPrice: 'AED 32.0',
    },
    {
        image: image26,
        name: 'Blu Sparkling Pineapple 250 ml - Pack of 6',
        salePercentage: '-0%',
        originalPrice: 'AED 21.0',
    },


    // next
    {
        image: image27,
        name: 'Blu Sparkling 500 ml Lemon - Pack of 6',
        salePercentage: '-0%',
        originalPrice: 'AED 22.0',
    },
    {
        image: image27,
        name: 'Blu Sparkling 250 ml Lemon - Pack of 6',
        salePercentage: '-0%',
        originalPrice: 'AED 14.0',
    },
    {
        image: image27,
        name: 'Blu Sparkling Lemon & Mint 500 ml - Pack of 6',
        salePercentage: '-0%',
        originalPrice: 'AED 22.0',
    },

    // next

    {
        image: image28,
        name: 'Blu Sparkling Lemon & Ginger 500 ml - Pack of 6',
        salePercentage: '-0%',
        originalPrice: 'AED 32.0',
    },
    {
        image: image28,
        name: 'Blu Sparkling Lemon & Ginger 250 ml - Pack of 6',
        salePercentage: '-0%',
        originalPrice: 'AED 22.0',
    },
    {
        image: image5,
        name: 'Bulk Offer Oasis 1.5L Carton of 12 (Bundle of 2 Cartons)',
        salePercentage: '-20%',
        originalPrice: 'AED 75.0',
    },

    // next
    {
        image: image3,
        name: 'Bulk Offer Oasis 1.5L Carton of 12 (Bundle of 4 Cartons)',
        salePercentage: '-27%',
        originalPrice: 'AED 60.0',
    },
    {
        image: image4,
        name: 'Bulk Offer Oasis Still 200 ml Pack of 24 (Bundle of 2 Packs)',
        salePercentage: '-20%',
        originalPrice: 'AED 30.0',
    },
    {
        image: image2,
        name: 'Bulk Offer Oasis Still 200 ml Pack of 24 (Bundle of 4 Packs)',
        salePercentage: '-27%',
        originalPrice: 'AED 60.0',
    },
    {
        image: image4,
        name: 'Bulk Offer Oasis Still 330 ml Pack of 24 (Bundle of 2 Packs)',
        salePercentage: '-20%',
        originalPrice: 'AED 50.0',
    },
    {
        image: image2,
        name: 'Bulk Offer Oasis Still 330 ml Pack of 24 (Bundle of 4 Packs)',
        salePercentage: '-27%',
        originalPrice: 'AED 99.0',
    },
    {
        image: image4,
        name: 'Bulk Offer Oasis Still 500 ml Pack of 24 (Bundle of 2 Packs)',
        salePercentage: '-20%',
        originalPrice: 'AED 75.0',
    },
    {
        image: image2,
        name: 'Bulk Offer Oasis Still 500 ml Pack of 24 (Bundle of 4 Packs)',
        salePercentage: '-27%',
        originalPrice: 'AED 60.0',
    },
    {
        image: image7,
        name: 'Bulk Offer Oasis Zero 1.5 Litre – Pack of 6 bottles(Bundle of 4 Packs)',
        salePercentage: '-50%',
        originalPrice: 'AED 50.0',
    },
    {
        image: image6,
        name: 'Bulk Offer Oasis Zero 500 ml – Pack of 12 bottles (Bundle of 4 Packs)',
        salePercentage: '-15%',
        originalPrice: 'AED 36.0',
    },

    // next Juice
    {
        image: image29,
        name: 'Lacnor Healthy Living Grapefruit -1L x 1',
        salePercentage: '-0%',
        originalPrice: 'AED 7.61',
    },

    {
        image: image30,
        name: 'Lacnor Healthy Living Tomato 1Lx1',
        salePercentage: '-0%',
        originalPrice: 'AED 7.61',
    },

    {
        image: image31,
        name: 'Lacnor 100% Long Life Fruit Cocktail Juice 1L x 1',
        salePercentage: '-0%',
        originalPrice: 'AED 7.14',
    },

    // next
    {
        image: image32,
        name: 'Lacnor Long Life Orange -1L x 1',
        salePercentage: '-0%',
        originalPrice: 'AED 6.17',
    },
    {
        image: image33,
        name: 'Lacnor 100% Long Life Mango Juice 1L x 1',
        salePercentage: '-0%',
        originalPrice: 'AED 7.14',
    },
    {
        image: image34,
        name: 'Lacnor Long Life Mixed Berries & Fruit Blend Juice 1L x 1',
        salePercentage: '-0%',
        originalPrice: 'AED 7.19',
    },

    // next

    {
        image: image35,
        name: 'Lacnor 100% Long Life Pineapple Juice 1L x 1',
        salePercentage: '-0%',
        originalPrice: 'AED 7.55',
    },
    {
        image: image36,
        name: 'Lacnor 100% Long Life Apple Juice 1L x 1',
        salePercentage: '-0%',
        originalPrice: 'AED 7.55',
    },
    {
        image: image37,
        name: 'Lacnor Long Life Cranberry 1L x 1',
        salePercentage: '-0%',
        originalPrice: 'AED 11.23',
    },

    // next

    {
        image: image38,
        name: 'Lacnor 100% Long Life Fruit Cocktail Juice 180ml - Pack of 8',
        salePercentage: '-0%',
        originalPrice: 'AED 11.9',
    },
    {
        image: image39,
        name: 'Lacnor 100% Long Life Mango Juice 180ml - Pack of 8',
        salePercentage: '-0%',
        originalPrice: 'AED 11.9',
    },
    {
        image: image40,
        name: 'Lacnor Long Life Mixed Berries & Fruit Blend Juice 180ml - Pack of 8',
        salePercentage: '-0%',
        originalPrice: 'AED 11.9',
    },
    // next

    {
        image: image41,
        name: 'Lacnor 100% Long Life Apple Juice 180ml - Pack of 8',
        salePercentage: '-0%',
        originalPrice: 'AED 8.8',
    },
    {
        image: image42,
        name: 'Lacnor 100 % Long Life Orange Juice 180ml - Pack of 8',
        salePercentage: '-0%',
        originalPrice: 'AED 8.8',
    },
    {
        image: image43,
        name: 'Lacnor 100% Long Life Pineapple Juice 180ml - Pack of 8',
        salePercentage: '-0%',
        originalPrice: 'AED 8.8',
    },

    // next

    {
        image: image44,
        name: 'Lacnor Long Life Orange Juice 125ml - Pack of 6',
        salePercentage: '-0%',
        originalPrice: 'AED 6.19',
    },
    {
        image: image45,
        name: 'Lacnor Long Life Apple Juice 125ml - Pack of 6',
        salePercentage: '-0%',
        originalPrice: 'AED 6.19',
    },
    {
        image: image46,
        name: 'Melco Long Life Drink Orange 180ml - Carton of 27',
        salePercentage: '-0%',
        originalPrice: 'AED 25.71',
    },


    // next

    {
        image: image47,
        name: 'Bulk Offer Lacnor 100 % Long Life Apple Juice 1L (Bundle of 5 Pieces)',
        salePercentage: '-14%',
        originalPrice: 'AED 37.75',
    },
    {
        image: image48,
        name: 'Bulk Offer Lacnor 100 % Long Life Fruit Cocktail Juice 1L (Bundle of 5 Pieces)',
        salePercentage: '-14%',
        originalPrice: 'AED 38.1',
    },
    {
        image: image49,
        name: 'Bulk Offer Lacnor 100% Long Life Apple Juice 180ml Pack of 8 (Bundle of 5 Outers)',
        salePercentage: '-14%',
        originalPrice: 'AED 44.0',
    },
    // next

    {
        image: image50,
        name: 'Bulk Offer Lacnor Healthy Living Pomegranate 1L (Bundle of 5 Pieces)',
        salePercentage: '-14%',
        originalPrice: 'AED 47.6',
    },
    {
        image: image51,
        name: 'Lacnor Healthy Living Grapefruit 250 ml - Carton of 24',
        salePercentage: '-0%',
        originalPrice: 'AED 57.0',
    },
    {
        image: image56,
        name: 'Melco Long Life Drink Fruit Cocktail 180ml - Carton of 27 (Bundle of 5 Cartons)',
        salePercentage: '-14%',
        originalPrice: 'AED 128.6',
    },
    // next

    {
        image: image55,
        name: 'Melco Long Life Drink Mango 180ml - Carton of 27 (Bundle of 5 Cartons)',
        salePercentage: '-14%',
        originalPrice: 'AED 128.6',
    },
    {
        image: image54,
        name: 'Melco Long Life Drink Orange 180ml - Carton of 27 (Bundle of 5 Cartons)',
        salePercentage: '-14%',
        originalPrice: 'AED 128.6',
    },

    // next dairy 

    {
        image: image57,
        name: 'Lacnor Long Life Double Chocolate Milk 250ml - Pack of 6',
        salePercentage: '-0%',
        originalPrice: 'AED 18.0',
    },
    {
        image: image58,
        name: 'Lacnor Long Life High Protein Milk - 1Lx1',
        salePercentage: '-0%',
        originalPrice: 'AED 7.61',
    },
    {
        image: image59,
        name: 'Lacnor Long Life High Protein Milk lL - Carton of 6',
        salePercentage: '-0%',
        originalPrice: 'AED 45.66',
    },

    // next
    {
        image: image60,
        name: 'Lacnor Long Life Full Cream Milk 1L - Pack of 4',
        salePercentage: '-0%',
        originalPrice: 'AED 18.0',
    },
    {
        image: image61,
        name: 'Lacnor Long Life Skimmed Milk 1L - Pack of 4',
        salePercentage: '-0%',
        originalPrice: 'AED 18.0',
    },
    {
        image: image62,
        name: 'Lacnor Long Life Low Fat Milk 1L - Pack of 4',
        salePercentage: '-0%',
        originalPrice: 'AED 18.0',
    },
    // next
    {
        image: image63,
        name: 'Lacnor Long Life Strawberry Milk 180 ml - Pack of 8',
        salePercentage: '-0%',
        originalPrice: 'AED 8.8',
    },
    {
        image: image64,
        name: 'Lacnor Long Life Chocolate Milk 180ml - Pack of 8',
        salePercentage: '-0%',
        originalPrice: 'AED 8.8',
    },
    {
        image: image65,
        name: 'Lacnor Long Life Banana Milk - 180ml Pack of 8',
        salePercentage: '-0%',
        originalPrice: 'AED 8.8',
    },
    // next
    {
        image: image66,
        name: 'Lacnor Long Life Full Cream Milk 180ml - Pack of 8',
        salePercentage: '-0%',
        originalPrice: 'AED 8.8',
    },
    {
        image: image67,
        name: 'Gulf & Safa Laban Up UHT 180ml â€“ Pack of 32',
        salePercentage: '-0%',
        originalPrice: 'AED 33.8',
    },
    {
        image: image68,
        name: 'Bulk Offer Lacnor Long Life Full Cream Milk 1L Pack of 4 (Bundle of 5 Outers)',
        salePercentage: '-14%',
        originalPrice: 'AED 90.0',
    },

    // next
    {
        image: image9,
        name: 'Bulk Offer Lacnor Long Life Chocolate Milk 180ml Pack of 8 (Bundle of 5 Outers)',
        salePercentage: '-14%',
        originalPrice: 'AED 44.0',
    },
    {
        image: image11,
        name: 'Bulk Offer Lacnor Long Life Full Cream Milk 180ml Pack of 8 (Bundle of 5 Packs)',
        salePercentage: '-14%',
        originalPrice: 'AED 44.0',
    },

    {
        image: image8,
        name: 'Bulk Offer Lacnor Long Life Strawberry Milk 180ml Pack of 8(Bundle of 5 Outers)',
        salePercentage: '-14%',
        originalPrice: 'AED 44.0',
    },
    // next
    {
        image: image69,
        name: 'Lacnor Long Life Milk Chocolate 125ml - Carton of 24 Pcs ( 4 Packs x 6)',
        salePercentage: '-0%',
        originalPrice: 'AED 25.7',
    },
    {
        image: image70,
        name: 'Lacnor Long Life Milk Strawberry 125ml - Carton of 24 Pcs ( 4 Packs x 6)',
        salePercentage: '-0%',
        originalPrice: 'AED 25.7',
    },

    // next acsec
    {
        image: image71,
        name: 'Electric Water Pump',
        salePercentage: '-0%',
        originalPrice: 'AED 10.0',
    },
    {
        image: image72,
        name: 'Manual Pump',
        salePercentage: '-0%',
        originalPrice: 'AED 40.0',
    },
    {
        image: image73,
        name: 'Plastic Dispenser',
        salePercentage: '-0%',
        originalPrice: 'AED 50.0',
    },

    // next
    {
        image: image74,
        name: 'Hot & Cold Electric Dispenser (Pearl White)',
        salePercentage: '-0%',
        originalPrice: 'AED 10.0',
    },
    {
        image: image1,
        name: 'Hot & Cold Dispenser Sanitization Service',
        salePercentage: '-33%',
        originalPrice: 'AED 75.0',
    },
    {
        image: image75,
        name: 'Magnetic Plastic Cup Holder',
        salePercentage: '-0%',
        originalPrice: 'AED 70.0',
    },
    // next
    {
        image: image76,
        name: 'Metal Bottle Rack (For 3 bottles)',
        salePercentage: '-0%',
        originalPrice: 'AED 175.0',
    },
    {
        image: image77,
        name: 'White Cup 6oz (pack of 1,000)',
        salePercentage: '-0%',
        originalPrice: 'AED 60.0',
    },
    {
        image: image78,
        name: 'Clear Plastic Cup 5oz (pack of 1,000)',
        salePercentage: '-0%',
        originalPrice: 'AED 30.0',
    },
    // next
    {
        image: image79,
        name: 'Clear Plastic Cup 7oz (pack of 1,000)',
        salePercentage: '-0%',
        originalPrice: 'AED 40.0',
    },
    {
        image: image80,
        name: 'Foam Cup 6oz (pack of 1,000)',
        salePercentage: '-0%',
        originalPrice: 'AED 78.48',
    },
    {
        image: image81,
        name: 'Paper Cup 6oz (pack of 1,000)',
        salePercentage: '-0%',
        originalPrice: 'AED 70.48',
    },
    // next
    {
        image: image82,
        name: 'Paper Cup 7oz with handle (pack of 1,000)',
        salePercentage: '-0%',
        originalPrice: 'AED 120.0',
    },

    {
        image: image16,
        name: 'Oasis Zero Sodium',
        salePercentage: '-0%',
        originalPrice: 'AED 4.8',
    },

];

export default products;
