import React from 'react';

import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';
import UnderHeaderComponent from '../components/UnderHeaderComponent';
import MainAcsecPage from '../components/MainAcsecPage';

function AccessoriesPage() {
    return (
        <div>
            <HeaderComponent />
            <UnderHeaderComponent />
            <MainAcsecPage />
            <FooterComponent />
        </div>
    );
}

export default AccessoriesPage;
