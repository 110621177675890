import React from 'react';

import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';
import UnderHeaderComponent from '../components/UnderHeaderComponent';
import ConfirmCompenent from '../components/ConfirmCompenent';

function ConfirmedPage() {
    return (
        <div>
            <HeaderComponent />
            <UnderHeaderComponent />
            <ConfirmCompenent />
            <FooterComponent />
        </div>
    );
}

export default ConfirmedPage;
